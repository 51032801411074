<template>
    <div>
        <v-btn
                v-if="history_response.length"
                @click="open=!open"
                text="History"
                color="blue"
                variant="tonal"
                class="mt-2 ml-2"
                append-icon="mdi-history"
        ></v-btn>
        <v-dialog v-model="open">
            <v-card
                title="History"
                class="pa-3"
            >
                <v-card-text>
                    <v-autocomplete
                        v-model="response"
                        label="History"
                        variant="outlined"
                        :items="history_response"
                        return-object
                        item-title="url"
                    >
                        <template v-slot:append v-if="response">
                            <div>
                                <v-btn
                                    @click="copy(response.url)"
                                    icon="mdi-clipboard-outline"
                                    variant="text"
                                    color="grey"
                                ></v-btn>
                                <v-btn
                                    @click="share_url"
                                    icon="mdi-share-variant-outline"
                                    variant="text"
                                    color="blue"
                                ></v-btn>
                            </div>
                        </template>
                    </v-autocomplete>
                </v-card-text>
                <v-card-actions
                    class="flex-column px-6"
                >
                    <div v-if="response" class="flex-column w-100">
                        <p>{{new Date(response.date).toLocaleString()}} : {{response.url}}</p>
                        <v-btn
                            @click="copy(JSON.stringify(response.data))"
                            text="Copy data"
                            append-icon="mdi-clipboard-outline"
                            variant="tonal"
                            color="grey"
                            block
                            class="mt-3 ma-0"
                        ></v-btn>
                        <v-btn
                            @click="share_data"
                            text="Share data"
                            variant="tonal"
                            color="blue"
                            block
                            append-icon="mdi-share-variant-outline"
                            class="mt-3 ma-0"
                        ></v-btn>
                        <v-btn
                            @click="show_json(response.data)"
                            text="Show"
                            variant="tonal"
                            color="blue"
                            block
                            append-icon="mdi-eye"
                            class="mt-3 ma-0"
                        ></v-btn>
                        <v-btn
                            @click="send_url_api(response.url)"
                            text="Resend"
                            append-icon="mdi-send"
                            variant="tonal"
                            color="blue"
                            block
                            class="mt-3 ma-0"
                            :loading="loading"
                        ></v-btn>
                    </div>
                    <v-btn
                        @click="close"
                        text="Close"
                        append-icon="mdi-close"
                        variant="tonal"
                        color="red"
                        block
                        class="mt-3"
                    ></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import router from "@/router";

export default {
    name: "ArvHistory",
    data: () => ({
        open: false,
        response: null,
        loading: false
    }),
    computed: {
        ...mapState(["history_response"])
    },
    methods: {
        ...mapMutations(["set_api_url", "set_current_data"]),
        ...mapActions(["get_data"]),
        copy(data) {
            navigator.clipboard.writeText(data);
        },
        share_url() {
            this.copy(`${window.location.origin}/?url=${this.response.url}`)
        },
        share_data() {
            const url = `${window.location.origin}/?data=${JSON.stringify(this.response.data)}`;
            if (url.length >= 2048) return console.error("URL too long") // todo make visual error
            this.copy(url)
        },
        async send_url_api(url) {
            this.loading = true;
            await this.set_api_url(url);
            await this.get_data();
            this.loading = false;
            this.close()
        },
        close() {
            this.open = false;
        },
        show_json(data) {
            this.set_current_data(data);
            router.push(`/?data=${JSON.stringify(data)}`);
        },
    }
}
</script>

<style scoped>

</style>