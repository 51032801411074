<template>
    <v-dialog
        :model-value="open"
        class="overflow-y-auto"
        transition="dialog-bottom-transition"
        persistent
    >
        <div class="d-flex">
            <v-btn
                @click="back"
                class="mb-3"
                color="blue"
                prepend-icon="mdi-keyboard-backspace"
                text="Back"
            ></v-btn>
            <div class="ma-auto bg-white w-75 overflow-x-auto text-no-wrap d-flex">
                <h1 class="mx-3">{{tab_number}} : </h1>
                <div class="my-auto link">
                    <span
                        @click="back_to(index)"
                        v-for="(path, index) in paths"
                        :key="index"
                        :class="index%2 ? 'text-grey-darken-2' : 'text-black'"
                        class="underline"
                    >{{path}}/</span>
                </div>
            </div>
            <v-btn
                @click="close_all"
                class="mb-3"
                color="blue"
                prepend-icon="mdi-home"
                text="Close"
            ></v-btn>
        </div>
        <v-card
            v-if="!current_data"
            title="No data"
            class="pa-3"
        ></v-card>
        <div class="overflow-scroll">
            <v-data-table
                v-if="typeof current_data === 'object' && current_data.length"
                :headers="headers"
                :items="current_data"
            >
                <template v-slot:item="{item}">
                    <tr>
                        <td v-if="typeof item.selectable !== 'object'">
                            {{ item.selectable }}
                        </td>
                        <td
                            v-else
                            v-for="(value, key, index) in item.selectable"
                            :key="index"
                            @click="more(value, key)"
                        >
                            <span v-if="value && typeof value !== 'object'">{{ value }}</span>
                            <v-btn
                                v-else-if="value && typeof value === 'object'"
                                text="More"
                                append-icon="mdi-plus"
                                color="info"
                                variant="text"
                            ></v-btn>
                            <v-icon
                                v-else
                                icon="mdi-border-none-variant"
                                color="grey"
                            ></v-icon>
                        </td>
                    </tr>
                </template>
            </v-data-table>
            <v-card v-else class="pa-3">
                <div>
                    <h1>{{ tab_key }}</h1>
                    <v-slider
                        v-if="typeof current_data !== 'object'"
                        v-model="font_size"
                        prepend-icon="mdi-minus"
                        append-icon="mdi-plus"
                        label="Font size"
                        :color="get_color"
                        :min="8"
                        :max="40"
                        :step="1"
                        @click:prepend="font_size_minus"
                        @click:append="font_size_plus"
                    ></v-slider>
                </div>
                <div class="d-flex flex-column" v-if="typeof current_data === 'object'">
                    <span
                        @click="more(value, key)"
                        v-for="(value, key, index) in this.current_data"
                        :key="index"
                        style="min-height: 36px"
                    >
                        <span class="text-grey-darken-2">{{key}} : </span>
                        <span v-if="typeof value !== 'object'">{{value}}</span>
                        <v-btn
                            v-else-if="value"
                            text="More"
                            append-icon="mdi-plus"
                            color="info"
                            variant="text"
                        ></v-btn>
                        <v-icon
                            v-else
                            icon="mdi-border-none-variant"
                            color="grey"
                        ></v-icon>
                    </span>
                </div>
                <div v-else>
                    <pre :style="`font-size: ${get_font_size}`">{{JSON.stringify(current_data)}}</pre>
                    <v-btn
                        v-if="isUrl(current_data)"
                        @click="send_url_api(current_data)"
                        class="mt-3"
                        text="Send with this url"
                        append-icon="mdi-send"
                        color="info"
                        variant="tonal"
                        block
                        :loading="loading"
                    ></v-btn>
                </div>
            </v-card>
        </div>
        <arv-table
            @close_all="close_all"
            @close="close"
            @back_to="back_to"
            :open="more_open"
            :current_data="open_current_data"
            :tab_number="tab_number+1"
            :tab_key="open_tab_key"
            :paths="[...paths, open_tab_key]"
        ></arv-table>
    </v-dialog>
</template>

<script>

import {mapActions, mapMutations, mapState} from "vuex";

export default {
    name: 'ArvTable',
    props: {
        current_data: Object,
        root: Boolean,
        open: Boolean,
        tab_number: Number,
        tab_key: String,
        paths: Array
    },
    data: () => ({
        loading: false,
        headers: [],
        open_current_data: [],
        more_open: false,
        open_tab_key: null,
        font_size: 10,
        isUrl: v => /^(?:(?:https?|ftp):\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}|(?:\d{1,3}\.){3}\d{1,3})(?::\d{1,5})?(?:\/[^\s]*)?$/.test(v),
    }),
    computed: {
        ...mapState(["api_url"]),
        get_font_size() {
            return `${this.font_size/10}em`;
        },
        get_color() {
            const rgb_value = 255 - this.font_size * 2;
            return `rgb(${rgb_value}, ${rgb_value}, ${rgb_value})`;
        }
    },
    methods: {
        ...mapMutations(["clear_current_data", "set_api_url"]),
        ...mapActions(["get_data"]),
        set_headers(current_data) {
            if (!current_data) return this.back();
            let headers_set = new Set();
            for (let data of current_data) {
                const entries = Object.entries(data)
                for (const [key] of entries) {
                    headers_set.add(key);
                }
            }
            let headers = [];
            headers_set.forEach(header => {
                headers.push({title: `${header}`, key: header})
            })
            this.headers = headers;
        },
        async back() {
            await this.wait(100);
            await this.$emit("close");
        },
        async back_to(index) {
            if (this.paths.length > index+1) {
                await this.back()
                await this.$emit("back_to", index);
            }
        },
        async close() {
            this.open_current_data = [];
            this.more_open = false;
            this.open_tab_key = null;
        },
        async close_all() {
            await this.back()
            await this.$emit("close_all");
        },
        more(value, key) {
            if (value === null) return
            this.open_current_data = value;
            this.open_tab_key = key;
            this.more_open = true;
        },
        wait(ms) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, ms);
            });
        },
        font_size_minus() {
            if (this.font_size > 8) {
                this.font_size--;
            }
        },
        font_size_plus() {
            if (this.font_size < 40) {
                this.font_size++;
            }
        },
        async send_url_api(url) {
            if (this.isUrl(url)) {
                this.loading = true;
                await this.set_api_url(url);
                await this.get_data();
                this.loading = false;
                this.back_to(0);
            }
        }
    },
    watch: {
        current_data() {
            if (!this.current_data) return this.back();
            if (!this.current_data?.length || typeof this.current_data === "string") return;
            const current_data = this.current_data.map(data => {
                if (typeof data !== "object") {
                    data = { [this.tab_key] : data };
                }
                return data
            })
            this.set_headers(current_data);
        }
    }
}
</script>

<style scoped>
pre {
    border: 1px solid black;
    padding: 5px;
}
span.underline:hover {
    text-decoration: underline;
    color: rgb(33,150,243) !important;
    cursor: pointer;
}
div.link {
    text-decoration: underline;
}
div.link:hover {
    text-decoration: none;
}
</style>