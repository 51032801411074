<template>
    <div>
        <h1 class="text-center pa-3">Api Response Visualization</h1>
        <arv-request></arv-request>
        <arv-table
                v-if="current_data"
                @close="clear_current_data"
                :current_data="current_data"
                root
                open
                :tab_number="1"
                :paths="['']"
                tab_key=""
        ></arv-table>
    </div>
</template>

<script>
import {defineComponent} from 'vue';
import ArvTable from "@/components/ArvTable.vue";
import ArvRequest from "@/components/ArvRequest.vue";
import {mapMutations, mapState} from "vuex";

// Components

export default defineComponent({
    name: 'HomeView',
    props: {
        url: String,
        data: String
    },
    components: {ArvRequest, ArvTable},
    computed: {
        ...mapState(["current_data"])
    },
    methods: {
        ...mapMutations(["clear_current_data", "set_api_url", "set_current_data"])
    },
    created() {
        if (this.data) {
            this.set_current_data(JSON.parse(this.data))
        }
        if (this.url) {
            this.set_api_url(this.url)
        }

    }
});
</script>
