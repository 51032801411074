<template>
    <v-form
        @submit.prevent="submit"
        ref="form"
        v-model.lazy="valid"
    >
        <div class="d-flex mb-2">
            <v-text-field
                @paste.capture="show_json"
                v-model="json"
                label="JSON"
                variant="outlined"
                clearable
                persistent-clear
                color="grey"
                :autocomplete="false"
                :rules="[ruleJSON]"
            >
                <template v-slot:append v-if="json && ruleJSON(json) === true">
                    <div>
                        <v-btn
                            @click="copy(json)"
                            icon="mdi-clipboard-outline"
                            variant="text"
                            color="grey"
                        ></v-btn>
                        <v-btn
                            @click="share_data"
                            icon="mdi-share-variant-outline"
                            variant="text"
                            color="blue"
                        ></v-btn>
                    </div>
                </template>
            </v-text-field>
            <v-btn
                @click="show_json"
                v-if="json && ruleJSON(json) === true"
                class="mt-2 ml-2"
                variant="tonal"
                color="blue"
                text="Show"
                append-icon="mdi-eye"
            ></v-btn>
        </div>
        <div class="d-flex">
            <v-text-field
                @paste.capture="url_trim"
                @click:clear.prevent="reset"
                @update:model-value="set_split_url"
                @focusout="set_url"
                v-model="url"
                label="Url"
                variant="outlined"
                :autocomplete="false"
                autofocus
                clearable
                color="grey"
                :rules="[ruleURL]"
            >
                <template v-slot:append v-if="url && ruleURL(url) === true">
                   <div>
                       <v-btn
                           @click="copy(url)"
                           icon="mdi-clipboard-outline"
                           variant="text"
                           color="grey"
                       ></v-btn>
                       <v-btn
                           @click="share_url"
                           icon="mdi-share-variant-outline"
                           variant="text"
                           color="blue"
                       ></v-btn>
                   </div>
                </template>
            </v-text-field>
            <arv-history></arv-history>
        </div>
        <div class="d-flex">
            <v-checkbox
                    @update:model-value="set_url"
                    v-model="secure"
                    :label="get_secure"
                    true-icon="mdi-lock-check"
                    false-icon="mdi-lock-open-remove"
                    color="success"
            ></v-checkbox>
            <v-text-field
                    @update:model-value="set_url"
                    v-model="baseURL"
                    label="Base url"
                    variant="outlined"
                    color="blue"
            ></v-text-field>
            <v-text-field
                    @update:model-value="set_url"
                    v-model="port"
                    label="Port"
                    variant="outlined"
                    type="number"
                    color="blue"
            ></v-text-field>
            <v-text-field
                    @update:model-value="set_url"
                    v-model="route"
                    label="Route"
                    variant="outlined"
                    color="blue"
            ></v-text-field>
        </div>
        <v-btn
            :disabled="!valid"
            :loading="loading"
            type="sumbit"
            variant="tonal"
            color="blue"
            text="Send"
            block
            append-icon="mdi-send"
        ></v-btn>
    </v-form>
</template>

<script>
import {nextTick} from "vue";
import {mapActions, mapMutations, mapState} from "vuex";
import ArvHistory from "@/components/ArvHistory.vue";
import router from "@/router";

export default {
    name: "ArvRequest",
    components: {ArvHistory},
    data: () => ({
        json: null,
        url: "",
        baseURL: "",
        port: "",
        route: "",
        secure: false,
        valid: false,
        loading: false,
        // regex url
        ruleURL: v => /^(?:(?:https?|ftp):\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}|(?:\d{1,3}\.){3}\d{1,3})(?::\d{1,5})?(?:\/[^\s]*)?$/.test(v) || "URL must be valid",
        ruleJSON: v => {
            if (!v) return true;
            try {
                JSON.parse(v)
                return true
            } catch (e) {
                return "JSON must be valid"
            }
        }
    }),
    computed: {
        ...mapState(["current_data", "api_url"]),
        get_secure() {
            return this.secure ? "https://" : "http://";
        },
        get_port() {
            return this.port ? `:${this.port}` : "";
        },
        url_share() {
            return `/?url=${this.url}`;
        }
    },
    methods: {
        ...mapMutations(["set_api_url", "set_current_data"]),
        ...mapActions(["get_data"]),
        async submit() {
            this.json = null;
            await nextTick();
            if ((await this.$refs.form.validate()).valid) {
                this.loading = true;
                await this.set_api_url(this.url);
                await this.get_data();
                this.json = JSON.stringify(this.current_data);
                this.loading = false;
                if (!this.current_data) {
                    console.error("No data")
                }
            }
        },
        show_json() {
            if (this.ruleJSON(this.json) === true) {
                this.set_current_data(JSON.parse(this.json));
                router.push(`/?data=${this.json}`);
            }
        },
        reset() {
            this.url = "";
            this.baseURL = "";
            this.port = "";
            this.route = "";
            this.secure = false;
            this.loading = false;
        },
        async set_url() {
            await nextTick();
            this.url = `${this.get_secure}${this.baseURL}${this.get_port}${this.route}`;
            if (this.ruleURL(this.url) === true) {
                await router.push(this.url_share)
            }
        },
        async set_split_url() {
            await nextTick();
            this.set_api_url();
            if (!this.url) return;
            try {
                const url = new URL(this.url);
                this.secure = url.protocol === "https:";
                this.baseURL = url.hostname;
                this.port = url.port;
                this.route = url.pathname + url.search;
                this.set_url();
            } catch (e) {
                console.error("Invalid URL")
            }
        },
        async url_trim() {
            await nextTick()
            this.url = this.url.trim();
        },
        copy(data) {
            navigator.clipboard.writeText(data);
        },
        share_url() {
            this.copy(window.location.origin + this.url_share)
        },
        share_data() {
            const url = `${window.location.origin}/?data=${this.json}`;
            if (url.length >= 2048) return console.error("URL too long") // todo make visual error
            this.copy(url)
        }
    },
    created() {
        if (this.current_data) {
            this.json = JSON.stringify(this.current_data);
        }
        if (this.api_url) {
            this.url = this.api_url;
            this.set_split_url()
        }
    },
    watch: {
        api_url() {
            this.url = this.api_url;
        },
        current_data(new_data) {
            if (new_data) {
                this.json = JSON.stringify(this.current_data);
            }
        }
    }
}
</script>

<style scoped>

</style>