import axios from "axios";


const myAxios = axios.create({})


myAxios.interceptors.request.use(
    config => {
        return {...config, headers: {}}
    },
    error => {
        return Promise.reject(error)
    }
)

myAxios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        return error
    }
)


export default myAxios