<template>
  <v-app>
    <v-main class="d-grid">
      <v-container class="ma-auto">
          <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>

<style>
.d-grid {
    display: grid;
}
</style>