import { createStore } from 'vuex'
import requestService from "@/services/request.service";


export default createStore({
  state: {
    api_url: "",
    current_data: null,
    history_response: []
  },
  getters: {},
  mutations: {
    set_current_data: (state, data) => {
      state.current_data = data;
    },
    add_history_response: (state, data) => {
      state.history_response.splice(0, 0, {
        url: state.api_url,
        data: data,
        date: Date.now()
      })
    },
    set_api_url: (state, url) => {
        state.api_url = url;
    },
    clear_current_data: (state) => {
      state.current_data = null;
    },
  },
  actions: {
    async get_data({commit, state}) {
      try {
        const res = await requestService.get_data(state.api_url)
        await commit("set_current_data", res.data)
        await commit("add_history_response", res.data)
      } catch (e) {
        console.error(e)
      }
    },
  },
  modules: {
  }
})
